import { FormattedMessage as T, useIntl } from 'react-intl';

import { useGlobalConfig } from '../../hooks/configContext';
import Head from '../Head/Head';

import ErrorPage from '../ErrorPage/ErrorPage';
import OtovoEmailLink from '../OtovoEmailLink';

import RainbowLink from '../RainbowLink/RainbowLink';
import messages from './messages';

type Props = {
  children?: React.ReactNode;
  headTitle?: string;
  headDescription?: string;
  includeContactUs?: boolean;
  title?: React.ReactNode;
  cta?: React.ReactNode;
  status?: number | string;
  illustration?: React.ReactNode;
};

const DefaultChildren = () => {
  const config = useGlobalConfig();

  return (
    <T
      {...messages.notFoundParagraph}
      values={{
        link: (
          <RainbowLink href={config.STOREFRONT_URL}>
            <T {...messages.frontpageRef} />
          </RainbowLink>
        ),
      }}
    />
  );
};

const ErrorStatusPage = ({
  children = <DefaultChildren />,
  headTitle = '',
  headDescription = '',
  includeContactUs = false,
  status = 404,
  cta,
  title = '',
  illustration,
}: Props) => {
  const intl = useIntl();
  return (
    <ErrorPage.Layout>
      <Head
        title={headTitle || intl.formatMessage(messages.headTitle)}
        description={
          headDescription || intl.formatMessage(messages.headDescription)
        }
      />
      <div className="md:flex">
        <ErrorPage.Heading className="text-peach-60">
          {status}
        </ErrorPage.Heading>
        <div className="md:ml-6">
          <div className="border-l-grey-20 md:border-l-2 md:pl-6">
            <ErrorPage.Heading>
              {title || <T {...messages.notFoundTitle} />}
            </ErrorPage.Heading>
            {children && <div className="text-grey-50 mb-6">{children}</div>}
          </div>
          {cta && <div className="mb-6 md:ml-6">{cta}</div>}
          {includeContactUs && (
            <p className="text-grey-50 text-base md:ml-7">
              <T
                {...messages.contactUs}
                values={{
                  email: <OtovoEmailLink />,
                }}
              />
            </p>
          )}
        </div>
      </div>
      {illustration && (
        <ErrorPage.Illustration>{illustration}</ErrorPage.Illustration>
      )}
    </ErrorPage.Layout>
  );
};

ErrorStatusPage.displayName = 'ErrorStatusPage';

export default ErrorStatusPage;
