import { useRouter } from 'next/router';
import { FormattedMessage as T } from 'react-intl';
import { useGlobalConfig } from '../hooks/configContext';
import BusinessUnitWrapper from './BusinessUnitWrapper';
import m from './Navigation/messages';

import MarketWrapper from './MarketWrapper';
import Link from './RainbowLink/RainbowLink';
import Spacer from './Spacer/Spacer';

const LegalLinks = () => {
  const { locale } = useRouter();
  const { STOREFRONT_URL, BU_CONFIG, urls: storefrontUrls } = useGlobalConfig();
  const privacyUrl =
    BU_CONFIG.supported_locales.length > 1
      ? `${STOREFRONT_URL}/${locale}/legal/privacy/`
      : `${STOREFRONT_URL}/legal/privacy/`;

  const year = new Date().getFullYear();

  const SpacerIcon = () => <Spacer type="lilac" className="inline-block" />;

  const linkClassName = 'text-lilac-20 text-sm font-normal';
  return (
    <>
      <BusinessUnitWrapper includeUnits={['iberdrola-fr']}>
        <>
          <Link
            href="https://www.iberdrola.fr/information/politique-de-confidentialite"
            target="_blank"
            className={linkClassName}
          >
            <T {...m.privacyTitle} />
          </Link>
          <SpacerIcon />
        </>
      </BusinessUnitWrapper>
      <BusinessUnitWrapper includeUnits={['vivi-it']}>
        <>
          <Link
            href="https://vivienergia.imgix.net/uploads/2020/04/03120534/Privacy-Policy_Aprile-20201.pdf"
            target="_blank"
            className={linkClassName}
          >
            <T {...m.privacyTitle} />
          </Link>
          <SpacerIcon />
        </>
      </BusinessUnitWrapper>
      <BusinessUnitWrapper includeUnits={['otovo-br']}>
        <Link
          href="https://holu.com.br/docs/politica-de-privacidade"
          target="_blank"
          className={linkClassName}
        >
          <T {...m.privacyTitle} />
        </Link>
        <SpacerIcon />
      </BusinessUnitWrapper>
      <BusinessUnitWrapper onlyCentral excludeUnits={['otovo-br']}>
        <Link href={privacyUrl} locale={locale} className={linkClassName}>
          <T {...m.privacyTitle} />
        </Link>
        <SpacerIcon />
      </BusinessUnitWrapper>
      {storefrontUrls?.imprintUrl && (
        <>
          <Link
            href={storefrontUrls.imprintUrl}
            className={linkClassName}
            target="_blank"
          >
            <T {...m.imprintTitle} />
          </Link>
          <SpacerIcon />
        </>
      )}
      {storefrontUrls?.termsPageUrl && (
        <BusinessUnitWrapper excludeUnits={['vivi-it', 'oktavia-it']}>
          <Link
            href={storefrontUrls.termsPageUrl}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.ourConditions} />
          </Link>
          <SpacerIcon />
        </BusinessUnitWrapper>
      )}

      {storefrontUrls?.leasingTermsPageUrl && (
        <BusinessUnitWrapper excludeUnits={['vivi-it']}>
          <Link
            href={storefrontUrls.leasingTermsPageUrl}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.ourLeasingConditions} />
          </Link>
          <SpacerIcon />
        </BusinessUnitWrapper>
      )}

      <MarketWrapper markets={['es']}>
        <>
          <Link
            href={`${STOREFRONT_URL}/legal/terms-of-use/`}
            target="_blank"
            className={linkClassName}
          >
            <T {...m.termsOfUse} />
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['gb']}>
        <>
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/Otovo_IDD.pdf"
            target="_blank"
            className={linkClassName}
          >
            Initial Disclosure Document
          </Link>
          <SpacerIcon />
          <Link
            href="https://otovoweb.s3.eu-central-1.amazonaws.com/PDF/Otovo_UK_Complaints_procedure.pdf"
            target="_blank"
            className={linkClassName}
          >
            Complaints Policy
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <MarketWrapper markets={['pt']}>
        <>
          <Link
            href="https://www.livroreclamacoes.pt/Inicio/"
            target="_blank"
            className={linkClassName}
          >
            Livro de reclamações
          </Link>
          <SpacerIcon />
        </>
      </MarketWrapper>
      <p className="text-lilac-40 inline-block pt-4 text-sm">
        © {BU_CONFIG.company_name} {BU_CONFIG.legal_suffix} {year}
      </p>
      <MarketWrapper markets={['gb']}>
        <div className="mt-6">
          <p className="text-lilac-20 text-xs">
            Otovo Limited is an appointed representative of Product Partnerships
            Limited which is authorised and regulated by the Financial Conduct
            Authority.{' '}
            <Link href="https://www.productpartnerships.com/">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Product Partnerships Limited's
            </Link>{' '}
            Otovo Limited is an appointed representative of Product Partnerships
            Limited which is authorised and regulated by the Financial Conduct
            Authority. Product Partnerships Limited’s FCA registration number is
            626349 and its address is Suite D2 Joseph’s Well, Hanover Walk,
            Leeds LS3 1AB. Product Partnerships Limited’s permitted business is
            to act as a Principal for a network of Appointed Representatives who
            arrange regulated credit facilities for customers who are purchasing
            goods from them.
            <br />
            Otovo Limited acts as a credit broker not a lender. We can only
            introduce you to Humm Group Limited (FRN: 954478) who may be able to
            offer you finance facilities for your purchase. We do not receive
            any commission for introducing customers to Humm Group Limited. You
            may be able to obtain finance for your purchase from other lenders
            and you are encouraged to seek alternative quotations. If you would
            like to know how we handle complaints, please ask for a copy of our
            complaints handling process. You can also find information about
            referring a complaint to the Financial Ombudsman Service (FOS) at
            (FOS) at{' '}
            <Link href="https://www.financial-ombudsman.org.uk/">
              financial-ombudsman.org.uk
            </Link>
            <br />
            Representative Example: For example for a PV solar system that costs
            £5,001 the monthly payment for a 4 year loan where the interest rate
            is 9.99% and the APR is 10.45% is expected to be £126.80 and the
            total interest paid over the entire period of the loan is expected
            to total £1,085.52.
          </p>
        </div>
      </MarketWrapper>
    </>
  );
};
export default LegalLinks;
