import ErrorStatusPage from '@otovo/shared/components/ErrorStatusPage/ErrorStatusPage';
import {
  GetStaticPropsReturnType,
  StaticPropsContext,
} from '@otovo/shared/lib/next/flowTypes';

import NextPage from '@otovo/shared/NextPage';
import { Otovo$BreezeConfig } from '@otovo/shared/types/otovoweb';
import { getStaticProps as getStaticMarketProps } from '../config/getProps';

const Error404 = () => {
  return (
    <NextPage>
      <ErrorStatusPage />
    </NextPage>
  );
};

export async function getStaticProps(
  context: StaticPropsContext,
): Promise<GetStaticPropsReturnType<Otovo$BreezeConfig>> {
  const { props } = await getStaticMarketProps(context);
  return {
    props,
  };
}
export default Error404;
